<template>
  <div class="analysis_look">
    <div class="info">
      <p class="tt">第4题：您的企业主要销售的产品有哪些，请罗列三种并做简要描述？</p>
      <div class="input">
        <input type="text" placeholder="搜索答案文本" />
        <a class="search" href="javascript:;">搜索</a>
      </div>
    </div>
    <div class="main_table table_tt">
      <table border="0" cellspacing="0" cellpadding="0">
        <thead>
        <tr>
          <th class="align_c" width="8%">序号</th>
          <th>企业名称</th>
          <th>答案文本</th>
        </tr>
        </thead>
      </table>
    </div>
    <div class="list2">
      <el-scrollbar class="quest-scroll">
        <div class="main_table table_bd">
          <table border="0" cellspacing="0" cellpadding="0">
            <tbody>
            <tr v-for="(item,index) in textContent" :key="index">
              <td class="align_c" width="8%">{{index+1}}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.value }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </el-scrollbar>
    </div>
    <div class="btn">
      <a class="close" @click="closeWindow" href="javascript:;">关闭窗口</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "looksurvey1",
  data(){
    return {
      textContent:[
        {
          name:'重庆市大足区聪聪五金制造有限公司重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车轮船、汽车'
        },
        {
        name:'重庆市大足区聪聪五金制造有限公司',
        value: '重庆市大足区聪聪五金制造有限公司重庆市大足区聪聪五金制造有限公司重庆市大足区聪聪五金制造有限公司'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        },
        {
          name:'重庆市大足区聪聪五金制造有限公司',
          value:'轮船、汽车'
        }
      ]
    }
  },
  mounted() {
    console.log(this.$route.query.type)
  },
  methods:{
    closeWindow(){
      window.close();
    }
  }
}
</script>

<style scoped lang="scss">
.analysis_look {
  position: fixed;
  bottom: 20px;
  top: 20px;
  padding-bottom: 100px;
  width: 50%;
  left: 50%;
  margin-left: -25%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(153, 153, 153, .15);
  .info {
    padding: 20px;
    .tt {
      margin-bottom: 6px;
      color: #242d33;
      font-size: 18px;
      line-height: 32px;
      padding-top: 0;
    }
    .blue {
      color: #1190f3;
    }
    .other {
      color: #98a0a6;
      padding-top: 7px;
      span {
        padding-right: 34px;
      }
      b {
        font-weight: normal;
      }
      .green {
        color: #04cd83;
      }
    }
    .input {
      height: 34px;
      padding: 15px 0 8px;
      input {
        float: left;
        height: 32px;
        line-height: 32px;
        border: 1px solid #eee;
        width: 338px;
        padding: 0 10px;
        margin-right: 10px;
      }
      .search {
        float: left;
        line-height: 34px;
        color: #fff;
        border-radius: 2px;
        width: 80px;
        text-align: center;
        background: #1190f3;
        font-weight: bold;
        color: #fff;
      }
    }
  }
  .list {
    position: absolute;
    top: 110px;
    bottom: 100px;
    left: 20px;
    right: 20px;
    border: 1px solid #eef0f3;
    border-radius: 3px;
    background: #fafbfc;
    line-height: 32px;
    h3 {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: bold;
    }
    li {
      border-bottom: 1px dashed #eee;
      padding-bottom: 11px;
      margin-bottom: 10px;
    }
    .item {
      padding: 0 20px;
      .ins {
        color: #60696f;
      }
    }
    .nano-content {
      padding: 14px 0;
    }
  }
  .table_tt {
    margin: 0 20px;
    height: 50px;
  }
  .list2 {
    position: absolute;
    top: 176px;
    bottom: 100px;
    left: 20px;
    right: 20px;
    .table_bd {
      table {
        background: #fff;
        border-top: none;
      }
    }
    .nano {
      .nano-pane {
        border-right: 1px solid #dbe1e5;
      }
    }
  }
  .btn {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 30px 0;
    font-size: 0;
    text-align: center;
    a,
    button {
      margin: 0 5px;
      display: inline-block;
      width: 104px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      font-size: 12px;
      color: #242d33;
      font-weight: bold;
      border: 1px solid #eee;
      background: #EEEEEE;
    }
    .sure {
      width: 118px;
      height: 40px;
      background-color: #3984F5;
      border-color: #3984F5;
      color: #fff;
      &:hover {
        background-color: #0073cc;
      }
    }
  }
}
.main_table {
  .filtrate {
    margin-bottom: 20px;
    .down_select {
      float: left;
      margin-right: 10px;
    }
    .btn {
      float: left;
      width: 70px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 2px;
      color: #fff;
      background-color: #1190f3;
      &:hover {
        background-color: #0073cc;
      }
    }
  }
  table {
    width: 100%;
    table-layout: fixed;
    text-align: left;
    border-top: 1px solid #dbe1e5;
    border-right: 1px solid #dbe1e5;
    word-wrap: break-word;
  }
  tr:hover {
    background-color: #f6fbfe;
  }
  th {
    background-color: #f6f7f9;
    line-height: 22px;
    border-bottom: 1px solid #dbe1e5;
    border-left: 1px solid #dbe1e5;
    padding: 8px 16px;
    font-weight: 400;
    color: #6C7880;
  }
  td {
    padding: 9px 16px;
    border-bottom: 1px solid #dbe1e5;
    border-left: 1px solid #dbe1e5;
    line-height: 22px;
  }
  .oe1 {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
  }
  .align_l {
    text-align: left;
  }
  .align_c {
    text-align: center;
  }
  .red {
    color: #fd5451;
  }
  .blue {
    color: #1190f3;
  }
  .checkbox {
    display: block;
    margin: 0 auto;
    width: 14px;
    height: 14px;
    border: 1px solid #dbe1e5;
    background-color: #fff;
    border-radius: 2px;
    cursor: pointer;
    &.on {
      background: url('~@/assets/statistics/public_icon.png') no-repeat 0 0;
      border-color: #1190f3;
    }
  }
  .operate {
    a {
      margin-right: 5%;
    }
  }
  .all_operate {
    margin-top: 20px;
    .btn {
      padding: 2px 0;
      .checkbox {
        margin: 5px 16px;
      }
      a {
        float: left;
        line-height: 26px;
        padding: 0 10px;
        border: 1px solid #b8c1c9;
        border-radius: 2px;
        color: #60696f;
        margin-right: 10px;
        &:hover {
          color: #0073cc;
          border-color: #0073cc;
        }
      }
    }
  }
}
::v-deep .quest-scroll {
  height: calc(100vh - 327px);
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-scrollbar__bar.is-vertical {
    width: 4px;
  }
  .el-scrollbar__thumb {
    background: #A7B2B8;
  }
}
</style>